import { extendTheme } from "@chakra-ui/react";
const theme = {
  fonts: {
    // heading: "Source Sans Pro",
    // heading: "Libre Baskerville",
    heading: "Lora",
    body: "Source Sans Pro",
  },
  colors: {
    primary: "rebeccapurple",
  },
};

export default extendTheme(theme);
