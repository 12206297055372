exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-certifications-index-js": () => import("./../../../src/pages/certifications/index.js" /* webpackChunkName: "component---src-pages-certifications-index-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-training-calendars-cqi-irca-js": () => import("./../../../src/pages/training-calendars/cqi-irca.js" /* webpackChunkName: "component---src-pages-training-calendars-cqi-irca-js" */),
  "component---src-pages-training-calendars-exemplar-global-js": () => import("./../../../src/pages/training-calendars/exemplar-global.js" /* webpackChunkName: "component---src-pages-training-calendars-exemplar-global-js" */),
  "component---src-pages-trainings-advance-implementation-js": () => import("./../../../src/pages/trainings/advance-implementation.js" /* webpackChunkName: "component---src-pages-trainings-advance-implementation-js" */),
  "component---src-pages-trainings-awareness-js": () => import("./../../../src/pages/trainings/awareness.js" /* webpackChunkName: "component---src-pages-trainings-awareness-js" */),
  "component---src-pages-trainings-index-js": () => import("./../../../src/pages/trainings/index.js" /* webpackChunkName: "component---src-pages-trainings-index-js" */),
  "component---src-pages-trainings-internal-auditor-js": () => import("./../../../src/pages/trainings/internal-auditor.js" /* webpackChunkName: "component---src-pages-trainings-internal-auditor-js" */),
  "component---src-pages-trainings-lead-auditor-js": () => import("./../../../src/pages/trainings/lead-auditor.js" /* webpackChunkName: "component---src-pages-trainings-lead-auditor-js" */),
  "component---src-pages-trainings-soft-skills-js": () => import("./../../../src/pages/trainings/soft-skills.js" /* webpackChunkName: "component---src-pages-trainings-soft-skills-js" */),
  "component---src-templates-certification-page-js": () => import("./../../../src/templates/CertificationPage.js" /* webpackChunkName: "component---src-templates-certification-page-js" */),
  "component---src-templates-training-advance-implementation-page-js": () => import("./../../../src/templates/TrainingAdvanceImplementationPage.js" /* webpackChunkName: "component---src-templates-training-advance-implementation-page-js" */),
  "component---src-templates-training-awareness-page-js": () => import("./../../../src/templates/TrainingAwarenessPage.js" /* webpackChunkName: "component---src-templates-training-awareness-page-js" */),
  "component---src-templates-training-internal-auditor-page-js": () => import("./../../../src/templates/TrainingInternalAuditorPage.js" /* webpackChunkName: "component---src-templates-training-internal-auditor-page-js" */),
  "component---src-templates-training-la-page-js": () => import("./../../../src/templates/TrainingLAPage.js" /* webpackChunkName: "component---src-templates-training-la-page-js" */)
}

