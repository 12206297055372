import "@fontsource/source-sans-pro";
import "@fontsource/lora";
import "./src/styles.css";
import React from "react";
import { Link, Image } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

const onInitialClientRender = () => {
  // Tawk.to live chat
  <script type="text/javascript">
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    {(function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/619e2dfc6bb0760a494426e5/1fl8u791i";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })()}
  </script>;
}

  // google tag manager
  // <script>
  //   {(function (w, d, s, l, i) {
  //     w[l] = w[l] || [];
  //     w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
  //     var f = d.getElementsByTagName(s)[0],
  //       j = d.createElement(s),
  //       dl = l !== "dataLayer" ? "&l=" + l : "";
  //     j.async = true;
  //     j.src = "https://www.googletagmanager.com/gtag/js?id=G-HZHRHWQMXZ" + i + dl;
  //     f.parentNode.insertBefore(j, f);
  //   })(window, document, "script", "dataLayer", "GTM-54L3G9D")}
  // </script>;

  <script>
    {(function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "GTM-TKRD5ZN")}
  </script>;

  <script>
    {(function () {
      const bodyElement = document.getElementsByTagName("body")[0];

      var tpl = document.createElement("template");
      tpl.innerHTML = `<noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TKRD5ZN"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>`;
      bodyElement.insertBefore(tpl.content, bodyElement.children[0]);
    })()}
  </script>;

const wrapRootElement = ({ element }) => {
  const text = encodeURI(
    "Hello, I visited your website and want to know more about ISO."
  );
  const number = "971509274320";
  return (
    <>
      <Helmet>
      <meta name="google-site-verification" content="koea-MRQ-yD-PIe06EH-RChzmCPyzcu8b8Fu4oW5orE" />
      </Helmet>

      {element}
      <Link href={`https://wa.me/${number}?text=${text}`}>
        <Image
          src="/whatsapp.png"
          sx={{
            position: "fixed",
            bottom: "0.75rem",
            left: "0.75rem",
            width: "70px",
            zIndex: "1000",
          }}
        />
      </Link>
    </>
  );
};

export { onInitialClientRender, wrapRootElement };
